module.exports = new Promise(resolve => {
      const MFE_DIRECTORY = "mfe-fare-optimization";
      const MFE_NAME = "fare_optimization";

      
    const currentTimestamp = Math.round(Date.now() / 60000); // cache busting every minute
    const currentUrl = location.protocol + "//" + location.host ;
    const remoteUrlWithVersion = currentUrl + "/" + MFE_DIRECTORY + "/remoteEntry.js?timestamp=" + currentTimestamp;
    const script = document.createElement('script')

    script.src = remoteUrlWithVersion
    script.onload = () => {
      // the injected script has loaded and is available on window
      // we can now resolve this Promise
      const proxy = {
        get: (request) => window[MFE_NAME].get(request).catch(error => {
           if(error?.toString().includes('ChunkLoadError')) {
              return window.location.reload();
           }
           throw error;
        }),
        init: (arg) => {
          try {
            return window[MFE_NAME].init(arg)
          } catch(e) {
            console.log('remote container already initialized')
          }
        }
      }
      resolve(proxy)
    }
    // inject this script with the src set to the versioned remoteEntry.js
    document.head.appendChild(script);

    });